/** @jsxImportSource @emotion/react */
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import "twin.macro";

const PermissionsContext = createContext(null);

const PermissionsProvider = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const extractPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decoded = jwtDecode(token);
        setPermissions(decoded.permissions ?? []);
      } catch (error) {
        console.warn("Unable to get access token", error);
      } finally {
        setIsLoading(false);
      }
    };

    void extractPermissions();
  }, [getAccessTokenSilently]);

  const hasPermission = (permission) => permissions.includes(permission);

  return (
    <PermissionsContext.Provider value={{ isLoading, permissions, hasPermission }} {...props} />
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (context === null) {
    throw new Error("usePermissions has to be used within <PermissionsProvider />");
  }
  return context;
};

export default PermissionsProvider;
