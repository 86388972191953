// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOrganization } from "../OrganizationContext";
import { useApi } from "../shared/useApi";

/**
 * @typedef TypeRiskAnalysisProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {String} status
 * @property {string} title
 * @property {string} type
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & TypeRiskAnalysisProps} TypeRiskAnalysis */

export const TypesRiskAnalysis = "typesRiskAnalysis";

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<TypeRiskAnalysis>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<TypeRiskAnalysis>>} */
export const useTypesRiskAnalysisQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([TypesRiskAnalysis, selectedOrganization, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "_id", ...query } = searchParams;
    const response = await api.get(`v1/types-risk-analysis`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

/** @type {(TypeRiskAnalysisId: string) => import("react-query").UseQueryResult<TypeRiskAnalysis>} */
export const useTypeRiskAnalysisByIdQuery = (typeRiskAnalysisId) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([TypesRiskAnalysis, selectedOrganization, typeRiskAnalysisId], async () => {
    return api.get(`v1/types-risk-analysis/${typeRiskAnalysisId}`).json();
  });
};

/** @type {() => import("react-query").UseMutationResult<TypeRiskAnalysis[], unknown, TypeRiskAnalysis[]>} */
export const useUpsertManyTypeRiskAnalysisMutation = () => {
  const { mutateAsync: upsertTypeRiskAnalysis } = useUpsertTypeRiskAnalysisMutation();
  return useMutation(async (typesRiskAnalysis) => {
    return Promise.all(
      typesRiskAnalysis.map((typeRiskAnalysis) => upsertTypeRiskAnalysis(typeRiskAnalysis))
    );
  });
};

export const useFetchTypesRiskAnalysis = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(`v1/types-risk-analysis`, { searchParams: qs.stringify(searchParams) }).json();
  };
};

/** @type {() => import("react-query").UseMutationResult<TypeRiskAnalysis, unknown, TypeRiskAnalysis>} */
export const useUpsertTypeRiskAnalysisMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (typeRiskAnalysis) => {
      if (typeRiskAnalysis._id) {
        return api
          .patch(`v1/types-risk-analysis/${typeRiskAnalysis._id}`, { json: typeRiskAnalysis })
          .json();
      }
      return api.post(`v1/types-risk-analysis`, { json: typeRiskAnalysis }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TypesRiskAnalysis);
      },
    }
  );
};
