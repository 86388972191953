// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOrganization } from "../OrganizationContext";
import { useApi } from "../shared/useApi";

/** @typedef {("NST" | "NSF" | "Carcasse" | "PS" | "GI" | "ZB" | "Other")} DefectCategory */

/**
 * @typedef DefectProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {DefectCategory} category
 * @property {string} title
 * @property {string} description
 * @property {number} index
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & DefectProps} Defect */

export const Defects = "defects";

export const metaDefectsCategories = {
  NST: "indigo",
  NSF: "red",
  Carcasse: "green",
  PS: "yellow",
  GI: "purple",
  ZB: "pink",
  Other: "gray",
};

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<Defect>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<Defect>>} */
export const useDefectsQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([Defects, selectedOrganization, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "index", ...query } = searchParams;
    const response = await api.get(`v1/defects`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

/** @type {(defectId: string) => import("react-query").UseQueryResult<Defect>} */
export const useDefectByIdQuery = (defectId) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([Defects, selectedOrganization, defectId], async () => {
    return api.get(`v1/defects/${defectId}`).json();
  });
};

/** @type {() => import("react-query").UseMutationResult<Defect[], unknown, Defect[]>} */
export const useUpsertManyDefectMutation = () => {
  const { mutateAsync: upsertDefect } = useUpsertDefectMutation();
  return useMutation(async (defects) => {
    return Promise.all(defects.map((defect) => upsertDefect(defect)));
  });
};

export const useFetchDefects = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(`v1/defects`, { searchParams: qs.stringify(searchParams) }).json();
  };
};

/** @type {() => import("react-query").UseMutationResult<Defect, unknown, Defect>} */
export const useUpsertDefectMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (defect) => {
      if (defect._id) {
        return api.patch(`v1/defects/${defect._id}`, { json: defect }).json();
      }
      return api.post(`v1/defects`, { json: defect }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Defects);
      },
    }
  );
};
