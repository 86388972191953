/** @jsxImportSource @emotion/react */
import { useQuery } from "react-query";
import "twin.macro";
import { useOrganization } from "../OrganizationContext";
import { useApi } from "../shared/useApi";

export const CURRENT_STOCK = "current-stock";

/** @type {(dto: string) => import("react-query").UseQueryResult<number>} */
export const useDTOCurrentStock = (dto) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([CURRENT_STOCK, selectedOrganization, dto], async () => {
    const { stock } = await api.get(`v1/current-stock/${encodeURIComponent(dto)}`).json();
    return stock;
  });
};

/**
 * Display the current stock for the given DTO
 * @type {React.FC<{ dto: string }>}
 */
export const DTOCurrentStock = ({ dto }) => {
  const { data, status } = useDTOCurrentStock(dto);

  return (
    <>
      {status === "loading" && <div tw="h-4 my-0.5 bg-gray-100 w-32 rounded-md animate-pulse" />}
      {status === "success" && <p tw="text-sm text-gray-500 truncate">{data} available</p>}
    </>
  );
};
