// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOrganization } from "../OrganizationContext";
import { useApi } from "../shared/useApi";

/**
 * @typedef CircuitProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {String} status
 * @property {string} title
 * @property {string} country
 * @property {number} distance
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & CircuitProps} Circuit */

export const Circuits = "circuits";

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<Circuit>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<Circuit>>} */
export const useCircuitsQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([Circuits, selectedOrganization, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "_id", ...query } = searchParams;
    const response = await api.get(`v1/circuits`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

/** @type {(circuitId: string) => import("react-query").UseQueryResult<Circuit>} */
export const useCircuitByIdQuery = (circuitId) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([Circuits, selectedOrganization, circuitId], async () => {
    return api.get(`v1/circuits/${circuitId}`).json();
  });
};

/** @type {() => import("react-query").UseMutationResult<Circuit[], unknown, Circuit[]>} */
export const useUpsertManyCircuitMutation = () => {
  const { mutateAsync: upsertCircuit } = useUpsertCircuitMutation();
  return useMutation(async (circuits) => {
    return Promise.all(circuits.map((circuit) => upsertCircuit(circuit)));
  });
};

export const useFetchCircuits = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(`v1/circuits`, { searchParams: qs.stringify(searchParams) }).json();
  };
};

/** @type {() => import("react-query").UseMutationResult<Circuit, unknown, Circuit>} */
export const useUpsertCircuitMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (circuit) => {
      if (circuit._id) {
        return api.patch(`v1/circuits/${circuit._id}`, { json: circuit }).json();
      }
      return api.post(`v1/circuits`, { json: circuit }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Circuits);
      },
    }
  );
};
