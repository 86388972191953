// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOrganization } from "../OrganizationContext";
import { Filter } from "../shared/QueryHelpers";
import { useApi } from "../shared/useApi";

export const RISKS_ANALYSIS = "risks-analysis";

/**
 * @typedef RiskAnalysisFile
 * @property {string} oid
 * @property {string} name
 * @property {string} type
 */

/** @typedef {("in_progress" | "archived")} RiskAnalysisStatus */

/**
 * @typedef RiskAnalysisRefDifferences
 * @property {string} title
 * @property {string} ref
 * @property {string} diff
 * @property {string} performanceImpacted
 */

/**
 * @typedef RiskAnalysisDimensionsReferences
 * @property {string} dimension
 * @property {string} dto
 * @property {string} cai
 * @property {string} referenceRun
 * @property {string} factory
 * @property {string} id
 * @property {RiskAnalysisRefDifferences[]} refDifferences
 */

/**
 * @typedef RiskAnalysisTarget
 * @property {string} circuit
 * @property {string} usageType
 * @property {string} stintNumber
 * @property {string} pressureRecommendation
 * @property {string} camberRecommendation
 * @property {boolean} targetValidated
 * @property {string} id
 */

/**
 * @typedef RiskAnalysisDimensionsStudies
 * @property {string} dimension
 * @property {string} dto
 * @property {string} volume
 * @property {string} cai
 * @property {string} factory
 * @property {RiskAnalysisTarget[]} targets
 * @property {string} id
 */

/**
 * @typedef RiskAnalysisProps
 * @property {string} [_id] The risk analysis server id. Will be completed when synchronized with server.
 * @property {string} [oid] The risk analysis identifier, might be undefined before inserted into IndexedDB
 * @property {import("../shared/AuditHelpers").User} [developer] The developer of the risk analysis.
 * @property {import("../shared/AuditHelpers").User[]} [experts] The experts of the risk analysis.
 * @property {string} [level]
 * @property {string} [date]
 * @property {RiskAnalysisStatus} [status] The risk analysis status. Will be updated automatically when fields are completed.
 * @property {string} [type]
 * @property {string} [title]
 * @property {string} [category]
 * @property {string} [analysisCategory]
 * @property {string} [analysisType]
 * @property {string} [analysisComments]
 * @property {[]} [analysisFiles]
 * @property {boolean} [isLocked] The risk analysis locked property.
 * @property {string} [initialSituation]
 * @property {[]} [contextFiles]
 * @property {RiskAnalysisDimensionsReferences[]} [dimensionsReferences]
 * @property {RiskAnalysisDimensionsStudies[]} [dimensionsStudies]
 *
 *
 * @property {string} [actionsToEngage]
 * @property {[]} [actionsFiles]
 * @property {string} [isRequestBlockage]
 * @property {string} [resultActions]
 * @property {[]} [resultFiles]
 * @property {string} [mspActions]
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & RiskAnalysisProps} RiskAnalysis */

/** @typedef {(searchParams?: import("../shared/QueryHelpers").SearchParams<RiskAnalysis>, selectedStatus?: string, isTreeView?: boolean) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<RiskAnalysis>>}} */
export const useRisksAnalysisQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([RISKS_ANALYSIS, searchParams, selectedOrganization], async () => {
    const { limit = 0, skip = 0, sort = "_id", ...query } = searchParams;
    const response = await api.get(`v1/risks-analysis`, {
      searchParams: qs.stringify({
        limit,
        skip,
        sort,
        ...Filter.and(query, { status: { $ne: "archived" } }),
      }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    let list = await response.json();

    return {
      totalCount,
      list,
    };
  });
};

/** @typedef {(searchParams?: import("../shared/QueryHelpers").SearchParams<RiskAnalysis>, selectedStatus?: string, isTreeView?: boolean) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<RiskAnalysis>>}} */
export const useRisksAnalysisCountQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery(["risks-analysis-count", searchParams, selectedOrganization], async () => {
    const { limit = 0, skip = 0, sort = "_id", ...query } = searchParams;
    const response = await api.get(`v1/risks-analysis`, {
      searchParams: qs.stringify({
        limit,
        skip,
        sort,
        ...Filter.and(query, { status: { $ne: "archived" } }),
      }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    let list = await response.json();

    return {
      totalCount,
      list,
    };
  });
};

/** @type {(tireTestId: string) => import("react-query").UseQueryResult<RiskAnalysis>} */
export const useRiskAnalysisByIdQuery = (riskAnalysisId) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([RISKS_ANALYSIS, riskAnalysisId, selectedOrganization], async () => {
    return api.get(`v1/risks-analysis/${riskAnalysisId}`).json();
  });
};

/** @type {() => import("react-query").UseMutationResult<RiskAnalysis, unknown, RiskAnalysis>} */
export const useUpsertRiskAnalysisMutation = () => {
  const api = useApi();
  return useMutation(async (riskAnalysis) => {
    if (riskAnalysis?._id) {
      return api.patch(`v1/risks-analysis/${riskAnalysis._id}`, { json: riskAnalysis }).json();
    }
    return api.post(`v1/risks-analysis`, { json: riskAnalysis }).json();
  });
};

/** @type {() => import("react-query").UseMutationResult<RiskAnalysis[], unknown, RiskAnalysis[]>} */
export const useUpsertManyRiskAnalysisMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (risksAnalysis) => {
      return Promise.all(
        risksAnalysis.map((riskAnalysis) => {
          if (riskAnalysis?._id) {
            return api
              .patch(`v1/risks-analysis/${riskAnalysis._id}`, { json: riskAnalysis })
              .json();
          }
          return api.post(`v1/risks-analysis`, { json: riskAnalysis }).json();
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RISKS_ANALYSIS);
      },
    }
  );
};

const defaultKeysToKeep = [
  "developer",
  "level",
  "experts",
  "date",
  "type",
  "category",
  "analysisCategory",
  "analysisType",
  "initialSituation",
  "contextFiles",
  "dimensionsStudies",
];

/** @type {() => import("react-query").UseMutationResult<RiskAnalysis, unknown, { riskAnalysisId: string, keysToKeep?: string[], defaultProps?: Partial<RiskAnalysis> }>} */
export const useDuplicateRiskAnalysisMutation = () => {
  const api = useApi();
  const { mutateAsync: upsertRiskAnalysis } = useUpsertRiskAnalysisMutation();
  return useMutation(
    async ({ riskAnalysisId, keysToKeep = defaultKeysToKeep, defaultProps = {} }) => {
      const riskAnalysisToDuplicate = await api.get(`v1/risks-analysis/${riskAnalysisId}`).json();

      // Create an empty risk analysis and update its values.
      // The risk analysis properties will be computed properly (e.g.: status and DTO stock movement)
      const duplicatedRiskAnalysis = await upsertRiskAnalysis({});

      const duplicatedProperties = Object.fromEntries(
        Object.entries(riskAnalysisToDuplicate).filter(([key]) => keysToKeep.includes(key))
      );
      return upsertRiskAnalysis({
        _id: duplicatedRiskAnalysis._id,
        ...duplicatedProperties,
        ...defaultProps,
      });
    }
  );
};
