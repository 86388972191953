// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOrganization } from "../OrganizationContext";
import { useApi } from "../shared/useApi";

export const REPOSITORY_ENTRIES = "repository-entries";

/** @typedef {("active" | "archived")} RepositoryEntryStatus */

/**
 * @typedef RepositoryEntryProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {RepositoryEntryStatus} status
 * @property {string} key
 * @property {string} value
 * @property {string} label
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & RepositoryEntryProps} RepositoryEntry */

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<RepositoryEntry>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<RepositoryEntry>>} */
export const useRepositoryEntriesQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([REPOSITORY_ENTRIES, selectedOrganization, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "value", ...query } = searchParams;
    const response = await api.get(`v1/repository-entries`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

export const useFetchRepositoryEntries = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(`v1/repository-entries`, { searchParams: qs.stringify(searchParams) }).json();
  };
};

/** @type {(value: string, key: string) => RepositoryEntry} */
export const createRepositoryEntry = (value, key) => {
  const sanitizedValue = value.trim();
  return {
    value: sanitizedValue,
    key,
    label: sanitizedValue,
    status: "active",
  };
};

/** @type {() => import("react-query").UseMutationResult<RepositoryEntry[], unknown, RepositoryEntry[]>} */
export const useUpsertManyRepositoryEntryMutation = () => {
  const { mutateAsync: upsertRepositoryEntry } = useUpsertRepositoryEntryMutation();
  return useMutation(async (repositoryEntries) => {
    return Promise.all(
      repositoryEntries.map((repositoryEntry) => upsertRepositoryEntry(repositoryEntry))
    );
  });
};

/** @type {() => import("react-query").UseMutationResult<RepositoryEntry, unknown, RepositoryEntry>} */
export const useUpsertRepositoryEntryMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (repositoryEntry) => {
      if (repositoryEntry._id) {
        return api
          .patch(`v1/repository-entries/${repositoryEntry._id}`, { json: repositoryEntry })
          .json();
      }
      return api.post(`v1/repository-entries`, { json: repositoryEntry }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPOSITORY_ENTRIES);
      },
    }
  );
};
