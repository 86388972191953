/** @jsxImportSource @emotion/react */
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "twin.macro";
import { queryClient } from "./App";
import { usePermissions } from "./PermissionsContext";

const OrganizationContext = createContext(null);

//#region - Save organization in session storage

const LOCAL_ORGANIZATION_KEY = "ORGANIZATION";

const getLocalOrganization = () => {
  return window.sessionStorage.getItem(LOCAL_ORGANIZATION_KEY);
};

const setLocalOrganization = (newOrganization) => {
  window.sessionStorage.setItem(LOCAL_ORGANIZATION_KEY, newOrganization);
};

//#endregion - Save organization in session storage

const OrganizationProvider = (props) => {
  // FIXME use hasPermisions instead of premissions
  const { permissions } = usePermissions();
  const history = useHistory();

  const [authorizedOrganizations, setAuthorizedOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const localOrganization = getLocalOrganization();

  // Organizations' access rules (based on user's permission):
  // If user has no permissions, the user has access to MSP (only)
  // If user has only "aoe-user", the user has access to AOE (only)
  // If user has both "aoe-user" and "msp-user", the user has access to AOE and MSP,
  // and the organization selector is displayed
  useEffect(() => {
    if (permissions.includes("msp-user") && permissions.includes("aoe-user")) {
      setAuthorizedOrganizations(["MSP", "AOE"]);
      if (localOrganization) {
        setSelectedOrganization(localOrganization);
      } else {
        setSelectedOrganization("MSP");
        setLocalOrganization("MSP");
      }
    } else if (permissions.includes("aoe-user")) {
      setAuthorizedOrganizations(["AOE"]);
      setSelectedOrganization("AOE");
    } else {
      setAuthorizedOrganizations(["MSP"]);
      setSelectedOrganization("MSP");
    }
  }, [permissions, localOrganization]);

  const changeOrganization = (newOrganization) => {
    setSelectedOrganization(newOrganization);
    queryClient.invalidateQueries();
    setLocalOrganization(newOrganization);
    history.replace("/");
  };

  return (
    <OrganizationContext.Provider
      value={{ selectedOrganization, authorizedOrganizations, changeOrganization }}
      {...props}
    />
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (context === null) {
    throw new Error("useOrganization has to be used within <OrganizationProvider />");
  }
  return context;
};

export default OrganizationProvider;
